import React from 'react'
import { NavLink } from 'react-router-dom';

function Design() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
      
      <div className="col-md-6 intros text-start" style={{color:  "rgba(52, 9, 85, 0.933)",}}>
        <h1 className="display-2">
          <span className="display-2--intro">UI/UX Design </span>
          <span className="display-2--description lh-base">
            "Designing intuitive interfaces and delightful experiences."
          </span>
        </h1>
      
      
      </div>
     
      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/design.jpg" alt="video illutration" className="img-fluid" />
          <NavLink  className="glightbox position-absolute top-50 start-50 translate-middle">
          
          </NavLink>
        </div>
      </div>
    </div>
  </div>
 </section>
<section id="services" className="services">
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 25}}>About UI/UX Design </h1>
      <div className="heading-line mb-1"></div>
    </div>
  
    <div className="row pt-2 pb-2 mt-0 mb-3">
      <div className="col-md-6 ">
        <div className="services__content" >
          <p></p>
          <h3 className="display-3--title ">UI/UX Design</h3>
          <p className="lh-lg">The basic step of of user attraction is the design, We are ready to shape your brand's prospective </p>
          <p className="lh-lg">
            UI/UX design is an art form, a delicate balance of aesthetics, functionality, and user empathy. It's the process of understanding user's needs, aspirations, and behaviours to design interfaces that seamlessly guide them through a digital journey.
          </p>
         
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <img src="folder/images/Design.png" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
            </div>
        </div>
      </div>
    </div>
  </div>

  </div>
  
</section>




<section id="services" className="services">
  <div className="container my-4">
<div className="row text-center">
  <h3 className="fw-bold lead mb-3">FAQ About UI/UX Design  </h3>
  <div className="heading-line "></div>
</div>
  
    <div className="row">
     
 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="services__content ">
          <div className="icon d-block fas fa-code-compare"></div>
          <h3 className="display-3--title mt-1">UI UX Design </h3>
          <div className="accordion my-4" id="accordionExample">
        
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is the difference between UI and UX design?

                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  UI (User Interface) design focuses on the visual and interactive elements of a product, such as layout, colors, typography, and icons. It deals with how the interface looks and feels to users. UX (User Experience) design, on the other hand, is concerned with the overall user experience, including usability, ease of navigation, and user satisfaction. It involves research, prototyping, and testing to
                   ensure that the product meets the needs and expectations of its users.</div>
              </div>
            </div>
          
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Why is UX design important?

                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  UX design is crucial because it directly impacts user satisfaction and engagement with a product. By understanding users' goals, behaviors, and pain points, UX designers can create intuitive and enjoyable experiences that meet user needs. A well-designed user experience can lead to increased user
                   retention, improved conversions, and positive brand perception.</div>
              </div>
            </div>
              
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What is the UX design process?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The UX design process typically involves several stages, including user research, creating user personas, information architecture, wireframing, prototyping, and usability testing. It starts with understanding the target audience and their requirements and progresses
                   through iterative design and testing cycles to refine and improve the user experience.</div>
              </div>
            </div>
           
          </div>
         
        </div>
      </div>

    
  </div>
    </div>
  </section> 
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    
    </div>
  )
}

export default Design