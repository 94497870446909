import React from 'react'
import { NavLink } from 'react-router-dom';

function CmsDevlopment() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
      
      <div className="col-md-6 intros text-start" style={{color:  "rgba(52, 9, 85, 0.933)", }}>
        <h1 className="display-2">
          <span className="display-2--intro">CMS Development</span>
          <span className="display-2--description lh-base">
            
"Empowering websites with flexible and efficient content management solutions."
          </span>
        </h1>
      
     
      </div>
     
      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/img/cms-development.svg" alt="video illutration" className="img-fluid" />
          <NavLink  className="glightbox position-absolute top-50 start-50 translate-middle">
           
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  </section>

<section id="services" className="services">
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 25}}>About CMS Development </h1>
      <div className="heading-line mb-1"></div>
    </div>
 
    <div className="row pt-2 pb-2 mt-0 mb-3">
      <div className="col-md-6 ">
        <div className="services__content" >
          <p></p>
          <h3 className="display-3--title ">CMS Development</h3>
          <p className="lh-lg">
            CMS development is the art of crafting dynamic platforms that enable businesses to harness the full potential of their content.
          </p>
         
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <img src="folder/images/img/cms-website.jpg" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
            </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</section>




<section id="services" className="services">
  <div className="container my-4">
<div className="row text-center">
  <h3 className="fw-bold lead mb-3">FAQ About CMS Development  </h3>
  <div className="heading-line "></div>
</div>
  
    <div className="row">
     
 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="services__content ">
          <div className="icon d-block fas fa-code-compare"></div>
          <h3 className="display-3--title mt-1">CMS Development</h3>
          <div className="accordion my-4" id="accordionExample">
            
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is a Content Management System (CMS)?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  A CMS is a software application that allows users to create, manage, and publish digital content on the web without requiring technical expertise. It provides an interface for content authors,
                   editors, and administrators to collaborate, organize, and update website content efficiently. </div>
              </div>
            </div>
           
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How can I extend the functionality of a CMS?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Most CMS platforms provide plugin or extension systems that allow you to extend the core functionality. These plugins or extensions enable you to add new features, integrate with third-party services, and enhance the capabilities of your website.
                   You can either choose from existing plugins/extensions or develop custom ones based on your requirements.</div>
              </div>
            </div>
              
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Can I develop a custom CMS?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Yes, it's possible to develop a custom CMS tailored to your specific requirements. Custom CMS development offers complete control over the features, design, and functionality of the system. However, developing a custom 
                  CMS can be time-consuming and costly compared to using existing CMS platforms.</div>
              </div>
            </div>
           
          </div>
         
        </div>
      </div>

    
  </div>
    </div>
  </section> 
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default CmsDevlopment