import React from 'react'
import { NavLink } from 'react-router-dom'

function WebDevlopment() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
     
      <div className="col-md-6 intros text-start" style={{color:  "rgba(52, 9, 85, 0.933)", }}>
        <h1 className="display-2">
          <span className="display-2--intro">Web Development </span>
          <span className="display-2--description lh-base">
            "Crafting digital experiences that captivate and connect."
          </span>
        </h1>
      
        
      </div>
  
      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/img/web-development-image.png" alt="video illutration" className="img-fluid" />
          <NavLink  className="glightbox position-absolute top-50 start-50 translate-middle">
          
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  </section>


<section id="services" className="services">
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 25}}>About Web Development </h1>
      <div className="heading-line mb-1"></div>
    </div>
  
    <div className="row pt-2 pb-2 mt-0 mb-3">
      <div className="col-md-6 ">
        <div className="services__content" >
          <p></p>
          <h3 className="display-3--title ">Web Development</h3>
          <p className="lh-lg">
            Let's start your business in the digital world with distinctive and built in website for your physical business
          </p>
          <p className="lh-lg">
            Web development is an art, a fusion of creativity and technical expertise. 
            It's a symphony of HTML, CSS, JavaScript, and a multitude of frameworks and tools, 
            orchestrated to craft seamless user experiences that leave a lasting impression.
          </p>
         
         
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <img src="folder/images/img/Web Devloper.jpg" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
            </div>
        </div>
      </div>
    </div>
  </div>

  </div>
  
</section>



<section id="services" className="services">
  <div className="container my-4">
<div className="row text-center">
  <h3 className="fw-bold lead mb-3">FAQ About Web Development  </h3>
  <div className="heading-line "></div>
</div>
  
    <div className="row">
     
 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="services__content ">
          <div className="icon d-block fas fa-code-compare"></div>
          <h3 className="display-3--title mt-1">Web Development </h3>
          <div className="accordion my-4" id="accordionExample">
            
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is web development?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Web development refers to the process of building, creating, and maintaining websites or web applications. It involves a combination of 
                  programming, design, and other skills to develop functional and visually appealing websites.  </div>
              </div>
            </div>
             
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  What are the essential skills required to become a web developer?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Key skills for web developers include proficiency in programming languages such as HTML, CSS, and JavaScript. Knowledge of server-side technologies (e.g., Node.js, PHP, Python) and databases (e.g., MySQL, MongoDB) is also valuable. Additionally, understanding version control systems (e.g., Git) 
                  and familiarity with web frameworks (e.g., React, Angular, Django) can be beneficial.</div>
              </div>
            </div>
               
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What is the difference between front-end and back-end development?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Front-end development focuses on the client-side of web development, dealing with the visual aspects and user interactions. It involves HTML, CSS, JavaScript, and frameworks like React or Angular. Back-end development, on the other hand, involves server-side programming, managing databases,
                   and handling data processing, using languages like Python, PHP, or Node.js.</div>
              </div>
            </div>
           
          </div>
         
        </div>
      </div>

   
  </div>
    </div>
  </section> 
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    
    </div>
  )
}

export default WebDevlopment