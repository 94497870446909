import React from "react";
import { NavLink } from "react-router-dom";

function Ecommerce() {
  window.scrollTo(0, 0);
  return (
    <div>
      <section id="home" className="intro-section">
        <div className="container">
          <div className="row align-items-center text-white">
            <div
              className="col-md-6 intros text-start"
              style={{
                color: "rgba(52, 9, 85, 0.933)",
                
              }}
            >
              <h1 className="display-2">
                <span className="display-2--intro">eCommerce Development</span>
                <span className="display-2--description lh-base">
                  "Building dynamic online stores that drive sales and elevate
                  customer experiences."
                </span>
              </h1>
            </div>

            <div className="col-md-6 intros text-end">
              <div className="video-box">
                <img
                  src="folder/images/img/ecommerce 2.jpg"
                  alt="video illutration"
                  className="img-fluid"
                />
                <NavLink className="glightbox position-absolute top-50 start-50 translate-middle"></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container my-4">
          <div className="row text-center">
            <h1 className="display-3 fw-bold" style={{ fontSize: "25px" }}>
              About eCommerce Development{" "}
            </h1>
            <div className="heading-line mb-1"></div>
          </div>

          <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 ">
              <div className="services__content">
                <p></p>
                <h3 className="display-3--title ">eCommerce Development </h3>
                <p className="lh-lg">
                  Ecommerce web development is the art of crafting immersive
                  digital storefronts that blend aesthetics, functionality, and
                  user experience to create compelling online shopping journeys.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start">
                <div className="">
                  <div className=" ">
                    <img
                      src="folder/images/img/ecommerce.png"
                      alt="Campany 1 logo"
                      title="Campany 1 Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container my-4">
          <div className="row text-center">
            <h3 className="fw-bold lead mb-3">
              FAQ About eCommerce Development{" "}
            </h3>
            <div className="heading-line "></div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <div className="services__content ">
                <div className="icon d-block fas fa-code-compare"></div>
                <h3 className="display-3--title mt-1">eCommerce Development</h3>
                <div className="accordion my-4" id="accordionExample">
                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is eCommerce development?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        eCommerce development involves creating online platforms
                        where businesses can sell products or services to
                        customers over the internet. It includes the design,
                        development, and implementation of online stores,
                        shopping carts, payment gateways, inventory management
                        systems, and other features necessary for running an
                        eCommerce business.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What are the key components of an eCommerce website?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        An eCommerce website typically consists of the following
                        components: Product catalog: A listing of products or
                        services with details, descriptions, and images.
                        Shopping cart: A feature that allows users to select and
                        store items they wish to purchase. Payment gateway
                        integration: A secure mechanism to process online
                        payments. Order management: Tools to manage orders,
                        track shipments, and handle returns or refunds. User
                        registration and customer accounts: Providing users with
                        the ability to create accounts, view order history, and
                        manage personal information. Inventory management:
                        Tracking product availability, stock levels, and
                        automated notifications for low stock items. Security
                        features: Implementing measures such as SSL encryption
                        and secure payment processing to protect customer data.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What platforms or CMS are commonly used for eCommerce
                        development?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        There are several popular eCommerce platforms and CMS
                        options, including: Shopify: A hosted eCommerce platform
                        with easy setup and extensive features. WooCommerce: A
                        plugin for WordPress that adds eCommerce functionality
                        to WordPress websites. Magento: A robust and highly
                        customizable open-source eCommerce platform.
                        BigCommerce: A scalable and feature-rich hosted
                        eCommerce platform. OpenCart: An open-source CMS
                        specifically designed for eCommerce. PrestaShop: Another
                        popular open-source eCommerce CMS with a wide range of
                        features.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NavLink className="shadow btn-primary rounded-circle back-to-top">
        <i className="fas fa-chevron-up"></i>
      </NavLink>
    </div>
  );
}

export default Ecommerce;
