import React from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function Home() {
   
  return (
    <div>
    <ScrollToTop />
      <section id="home" className="intro-section">
        <div className="container">
          <div className="row align-items-center text-white">
            <div
              className="col-md-6 intros text-start"
              style={{
                 
                color: "rgba(52, 9, 85, 0.933)",
               
           
                  
                }}
             
            >
              <h1 className="display-2">
                <span className="display-2--intro"   style={{
                  fontSize: "40px", 
                color: "rgba(52, 9, 85, 0.933)",
               
           
                  
                }}>Innovate. Transform. Thrive with Anskey.</span>
                <span className="display-2--description lh-base ">
                At Anskey, we're dedicated to crafting digital  excellence for a connected world.</span>
                <span className="display-2--description lh-base">
                "We are an IT firm delivering comprehensive software solutions and consultancy services globally."
                </span>
              </h1>

              <NavLink to={"/Contact"} className="nav-link">
                {" "}
                <button
                  type="button"
                  className="rounded-pill btn-rounded border-primary"
                >
                  Get in Touch
                  <span>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </button>
              </NavLink>
            </div>

            <div className="col-md-6 intros text-end">
              <div className="video-box">
                <img
                  src="folder/images/arts/intro-section-illustration.png"
                  alt="video illutration"
                  className="img-fluid"
                />
                <NavLink className="glightbox position-absolute top-50 start-50 translate-middle"></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services">
        <div className="container my-4">
          <div className="row text-center">
            <h1 className="display-3 fw-bold">About Us</h1>
            <div className="heading-line mb-1"></div>
          </div>

          <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 border-right">
              <div className="services__ntent">
                <p style={{color:"rgba(66, 27, 96, 0.933)", fontSize:"26px" }}></p>
                <h3 className="display-3--title mt-1">
                  Leading Web & Mobile App Development Company
                </h3>
                <p className="lh-lg my-5">
                  We are one of the fastest growing software development company
                  which offer high-end software solutions. Right from website
                  development to mobile app development, web applications to
                  portal development, we have the expert strength to cover the
                  different facets of the business world throughout
                  technology-driven solutions. We focus on delivering excellent
                  quality at affordable rates.
                </p>
                <NavLink to={"/Overview"} className="nav-link">
                  {" "}
                  <button
                    type="button"
                    className="rounded-pill btn-rounded border-primary"
                  >
                    About Us
                    <span>
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start">
                <div className="">
                  <div className=" ">
                    <img
                      src="folder/images/Side.png"
                      alt="Campany 1 logo"
                      title="Campany 1 Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-4">
            <div className="row text-center">
              <h1 className="display-3 fw-bold">Our Services</h1>
              <div className="heading-line mb-1"></div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services ">
                <div className="services__content">
                  <div className="icon d-block fas fa-code"></div>
                  <h3 className="display-3--title mt-1">Web Development</h3>
                  <p className="lh-lg">
                    Web development is an art, a fusion of creativity and
                    technical expertise. It's a symphony of HTML, CSS,
                    JavaScript, and a multitude of frameworks and tools,
                    orchestrated to craft seamless user experiences that leave a
                    lasting impression.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services ">
                <div className="services__content">
                  <div className="icon d-block fas fa-pen-nib"></div>
                  <h3 className="display-3--title mt-1">UI/UX Design</h3>
                  <p className="lh-lg">
                    UI/UX design is an art form, a delicate balance of
                    aesthetics, functionality, and user empathy. It's the
                    process of understanding user's needs, aspirations, and
                    behaviours to design interfaces that seamlessly guide them
                    through a digital journey.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services">
                <div className="services__content">
                  <div className="icon d-block fas fa-mobile"></div>
                  <h3 className="display-3--title mt-1">Mobile Development</h3>
                  <p className="lh-lg">
                    Mobile development is a captivating journey, where
                    innovation and user-centricity converge. It's the craft of
                    transforming ideas into tangible experiences, leveraging the
                    power of cutting-edge technologies to create seamless,
                    intuitive, and immersive mobile applications.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services">
                <div className="services__content">
                  <div className="icon d-block fas fa-store"></div>
                  <h3 className="display-3--title mt-1">
                    eCommerce Web Development
                  </h3>
                  <p className="lh-lg">
                    Ecommerce web development is the art of crafting immersive
                    digital storefronts that blend aesthetics, functionality,
                    and user experience to create compelling online shopping
                    journeys.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services">
                <div className="services__content">
                  <div className="icon d-block fas fa-laptop"></div>
                  <h3 className="display-3--title mt-1">Remote Hiring</h3>
                  <p className="lh-lg">
                  Remote hiring is not merely a passing trend; rather, it represents a fundamental shift in the way we approach work. This transformation unlocks a realm of opportunities, allowing companies to form teams with varied skill sets, experiences, and perspectives, irrespective of geographical constraints.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 services">
                <div className="services__content">
                  <div className="icon d-block fas fa-code"></div>
                  <h3 className="display-3--title mt-1">CMS Development</h3>
                  <p className="lh-lg">
                    CMS development is the art of crafting dynamic platforms
                    that enable businesses to harness the full potential of
                    their content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="campanies" className="campanies">
        <div className="container">
          <div className="row text-center">
            <h3 className="fw-bold lead mb-3">Technologies We Specialize In</h3>
            <div className="heading-line mb-5"></div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/mongodb-svgrepo-com.jpg"
                    alt="Campany 5 logo"
                    title="Campany 5 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Mongo DB</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/expressjs.png"
                    alt="Campany 3 logo"
                    title="Campany 3 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Expres Js</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/react-native.png"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> React Js</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  <img
                    src="folder/images/campanies/node js.png"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p>Node Js</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/Angular.png"
                    alt="Campany 1 logo"
                    title="Campany 1 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Angular</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/flutter.png"
                    alt="Campany 2 logo"
                    title="Campany 2 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Flutter</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/react-native.png"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> React-Native</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/laravel.png"
                    alt="Campany 4 logo"
                    title="Campany 4 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Laravel</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/mysql-logo-svgrepo-com.jpg"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> My SQL</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/vuejs.jpg"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                  />
                </NavLink>
                <p> Vue Js</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/campanies/dot_net_original_logo_icon_146546.png"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    className="img-fluid"
                    style={{ width: "96px", height: "96px" }}
                  />
                </NavLink>
                <p> Dot Net</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="campanies__logo-box shadow-sm">
                <NavLink>
                  {" "}
                  <img
                    src="folder/images/devop.png"
                    alt="Campany 6 logo"
                    title="Campany 6 Logo"
                    class="img-fluid"
                    style={{ width: "96px", height: "96px" }}
                  />
                </NavLink>
                <p> Dev-Ops</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services">
        <div className="container my-4">
          <div className="row text-center">
            <h3 className="fw-bold lead mb-3">Our Growth</h3>
            <div className="heading-line mb-5"></div>
          </div>

          <div className="row pt-2 pb-2 mt-0 mb-3">
            <div className="col-md-6 border-right">
              <div className="services__content">
                <h3 className="display-3--title mt-1">25+ Expertise</h3>
                <p className="lh-lg">
                  Multifaceted knowledge and guidance on diverse topics.
                </p>
                <h3 className="display-3--title mt-1">39+ Happy Customers</h3>
                <p className="lh-lg">
                  Satisfied clients with positive experiences.
                </p>
                <h3 className="display-3--title mt-1">
                  43+ Completed Projects
                </h3>
                <p className="lh-lg">
                  Successfully executed and delivered projects.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white p-4 text-start">
                <div className="">
                  <div className=" ">
                    <img
                      src="folder/images/campanies/social-media-3846597.svg"
                      alt="Campany 1 logo"
                      title="Campany 1 Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container my-4">
          <div className="row text-center">
            <h3 className="fw-bold lead mb-3">Our Strategy</h3>
            <div className="heading-line "></div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
              <div className="services__content ">
                <div className="icon d-block "></div>
                <h3 className="display-3--title mt-1">
                  Our working steps to organize our projects.
                </h3>
                <p className="lh-lg">
                  Creating a sound software product development strategy brings
                  significant benefits
                  <br />
                  <br />
                  It improves the efficiency of the development process. Every
                  software product development strategy should include resource
                  allocation and a timeline. This helps with task prioritization
                  and avoiding any delays in product delivery.
                </p>
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <h3 className="display-3--title mt-1">
                    Companies Who Have Chosen Us
                  </h3>
                  <div className="carousel-inner my-4">
                    <div className="carousel-item active">
                      <img
                        src="folder/images/partners/Sh.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/Final-Bohra-Industries.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/Oriella.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                     <div className="carousel-item">
                      <img
                        src="folder/images/partners/AFG.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/arido.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/greenvalley.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/MasterKoder.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/purica.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="folder/images/partners/sotac.jpg"
                        className="d-block "
                        alt="..."
                        style={{ width: "40%", height: "40%" }}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-lights fas fa-long-arrow-alt-left"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  ></button>
                  <button
                    className="btn btn-outline-lights fas fa-long-arrow-alt-right"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  ></button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <div className="services__content ">
                <div className="icon d-block fas fa-code-compare"></div>
                <h3 className="display-3--title mt-1">Steps </h3>
                <div className="accordion my-4" id="accordionExample">
                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Ideation and Requirements
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Ideation is commonly more thought of as being an
                        individual pursuit,This includes areas such as the
                        expected product launch deadline, software development
                        budget, key project milestones, and the estimated ROI.{" "}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        UI/UX & Design
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Once you accept the direction presented in the
                        wireframes, they will work on high-fidelity prototypes
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item shadow mb-3">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Development & Testing
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Development and coding is the longest stage in the
                        software product development process. After that process
                        time for testing to make sure there are no bugs or
                        errors that would make the software hard to use or
                        unusable
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="campanies" className="campanies">
      <div className="container">
        <div className="row text-center">
          <h3 className="fw-bold lead mb-3">Work Portfolio</h3>
          <div className="heading-line mb-5"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="campanies__logo-box shadow-sm">
              <NavLink>
                {" "}
                <img
                  src="folder/images/port/Agro.png"
                 
                  alt="Campany 5 logo"
                  title="Campany 5 Logo"
                  className="img-fluid"
                />
               </NavLink>
            
            </div>
            {/* <p> Agro Food Grain</p>*/}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="campanies__logo-box shadow-sm">
              <NavLink>
                {" "}
                <img
                src="folder/images/port/Bohra.png"
                  alt="Campany 3 logo"
                  title="Campany 3 Logo"
                  className="img-fluid"
                />
              </NavLink>
             
            </div>
            {/*<p> Bohra Industries</p>*/ } 
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="campanies__logo-box shadow-sm">
              <NavLink>
                {" "}
                <img
                  src="folder/images/port/GreenValley.png"
                  alt="Campany 6 logo"
                  title="Campany 6 Logo"
                  className="img-fluid"
                />
              </NavLink>
           
            </div>
           {/*     <p> Green Valley</p>*/}  
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="campanies__logo-box shadow-sm">
              <NavLink>
                {" "}
                <img
                  src="folder/images/port/Chai.png"
                  alt="Campany 6 logo"
                  title="Campany 6 Logo"
                  className="img-fluid"
                />
              </NavLink>
              
            </div>
          {/* <p> Chai Nasta</p>*/}  
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="campanies__logo-box shadow-sm">
              <NavLink>
                <img
                  src="folder/images/port/Hospital.png"
                  alt="Campany 6 logo"
                  title="Campany 6 Logo"
                  className="img-fluid"
                />
              </NavLink>
              
            </div>
            {/*<p>Shreeji Hospital</p>*/}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="campanies__logo-box shadow-sm">
            <NavLink>
              <img
                src="folder/images/port/furt.png"
                alt="Campany 6 logo"
                title="Campany 6 Logo"
                className="img-fluid"
              />
            </NavLink>
            
          </div>
          {/*<p>Shreeji Hospital</p>*/}
        </div>
          
       
        </div>
      </div>
    </section>
      <section id="contact" className="get-started">
        <div className="container">
          <div className="row text-center">
            <h1 className="display-3 fw-bold text-capitalize">Get started</h1>
            <div className="heading-line"></div>
          </div>

          <div className="row text-white">
            <div className="col-12 col-lg-6 gradient shadow p-3">
              <div className="cta-info w-100">
                <h4 className="fw-bold" style={{ fontSize: "30px" }}>
                  "Do you have something in mind for the project?"
                </h4>
                <p className="lh-lg">
                  Clearly outline the objectives, deliverables, and timeline for
                  your project. This will provide a clear direction and help you
                  stay focused.
                </p>
                <h3 className="display-3--brief">
                  What will be the next step?
                </h3>
                <ul className="cta-info__list">
                  <li>We'll prepare the proposal.</li>
                  <li>We'll discuss it together.</li>
                  <li>Let's start the Work.</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6 bg-white shadow p-3">
              <div className="form w-100 pb-2">
                <h4 className="display-3--title mb-5">Let's start your project</h4>
                <form
                  action="https://formsubmit.co/71595c79bb1f300f136eab11adc0ffbe"
                  method="POST"
                  className="row"
                >
                  <input
                    type="hidden"
                    name="_next"
                    value="https://anskeytechnology.com/"
                  ></input>
                  <input
                    type="hidden"
                    name="_subject"
                    value="Contact Form"
                  
                  ></input>
                  <input type="hidden" name="_captcha" value="false"  ></input>
                  <div className="col-lg-6 col-md mb-3">
                    <label
                      for="inputAddress2"
                      className=""
                      style={{ color: "#212121" }}
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="inputFirstName"
                      name="Name"
                      className="shadow form-control form-control-lg"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-6 col-md mb-3">
                    <label
                      for="inputAddress2"
                      className=""
                      style={{ color: "#212121" }}
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="inputLastName"
                      name="Email"
                      className="shadow form-control form-control-lg"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-6 mb-3  ">
                    <label
                      for="inputAddress2"
                      className="form-label "
                      style={{ color: "#212121" }}
                    >
                      What do you desire?
                    </label>
                    <select
                      className="form-select form-control-lg shadow "
                      name="Services"
                      aria-label="Default select example"
                      style={{ lineHeight: 2.1 }}
                    >
                      <option selected>Select...</option>
                      <option value="1">Web Development</option>
                      <option value="2">UI/UX Design</option>
                      <option value="3">Mobile Development</option>
                      <option value="4">eCommerce Web Development</option>
                      <option value="5">CMS Development</option>
                      <option value="6">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label
                      for="inputAddress2"
                      className="form-label"
                      style={{ color: "#212121" }}
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="inputEmail"
                      name="Number"
                      className="shadow form-control form-control-lg"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label
                      for="inputAddress2"
                      className="form-label"
                      style={{ color: "#212121" }}
                    >
                      Write your message here
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows="8"
                      className="shadow form-control form-control-lg"
                      autoComplete="off"
                    ></textarea>
                  </div>
                  <div className="text-center d-grid mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill pt-3 pb-3"
                    >
                      submit
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NavLink className="shadow btn-primary rounded-circle back-to-top">
        <i className="fas fa-chevron-up"  onClick={window.scrollTo(0, 0)}></i>
      </NavLink>
    </div>
  );
}



export default Home;
