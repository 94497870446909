import React from 'react'
import { NavLink } from 'react-router-dom';

function Form() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row">
      <h3 className="display-3--title text-center" >Job Application Form</h3>
  
      <form action="https://formsubmit.co/71595c79bb1f300f136eab11adc0ffbe" method="POST" className="row g-3" autoComplete="off">
      <input type="hidden" name="_next" value="https://anskeytechnology.com/"></input>
    <input type="hidden" name="_subject" value="Job Apply Form"></input>
    <input type="hidden" name="_captcha" value="false"></input>
      <div className="col-md-6">
        <label for="inputEmail4" className="form-label">First Name</label>
        <input type="text" name='First Name' className="form-control" id="inputEmail4" />
      </div>
      <div className="col-md-6">
        <label for="inputPassword4" className="form-label">Last Name</label>
        <input type="text" name='Last Name' className="form-control" id="inputPassword4" />
        
        
      </div>
      <div className="col-md-4">
        <label for="inputAddress" className="form-label">Email</label>
        <input type="email" name='Email' className="form-control" id="inputAddress" />
      </div>
      <div className="col-md-4">
        <label for="inputAddress2" className="form-label">Phone</label>
        <input type="text" name='Phone Number' className="form-control" id="inputAddress2" />
      </div>
      <div className="col-md-4">
        <label for="inputCity" className="form-label">Available start date</label>
        <input type="date" name='Available Date' className="form-control" id="inputCity" />
      </div>
      <div className="col-md-4">
        <label for="inputState" className="form-label">What position are you applying for?</label>
        <input type="text" name='Position' className="form-control" id="inputCity" />
      </div>
      <div className="col-md-4">
        <label for="inputState" className="form-label">Submit your resume by providing your resume URL:</label>
        <input type="text" name='Resume URL' className="form-control" id="inputCity" />
      </div>
      <div className="col-md-4">
        <label for="inputZip" className="form-label">Address</label>
        <input type="text" name='Address' className="form-control" id="inputZip" />
      </div>
      
      <div className="col-md-6">
        <label for="inputState" className="form-label">What is your current employment status?</label>

        <div className="form-check">
          <input className="form-check-input" type="radio" name="Current Status" id="flexRadioDefault1" />
          <label className="form-check-label" for="flexRadioDefault1">
            Employed
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="Current Status" id="flexRadioDefault2" checked />
          <label className="form-check-label" for="flexRadioDefault2">
            Self-Employed
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="Current Status" id="flexRadioDefault2" checked />
          <label className="form-check-label" for="flexRadioDefault2">
            Unemployed
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="Current Status" id="flexRadioDefault2" checked />
          <label className="form-check-label" for="flexRadioDefault2">
            Student
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <label for="exampleFormControlTextarea1" className="form-label">Would you like to list references?</label>
        <textarea className="form-control" name='References Massage' id="exampleFormControlTextarea1" rows="3" placeholder="If yes, please provide their names and email addresses."></textarea>
       
      </div>
      <div className="col-12 md-5" style={{display: "flex", justifyContent: "center", marginBottom:"18px"}}>
        <button type="submit" className="btn btn-primary" >Apply Now</button>
      </div>

    </form>
    </div>
  </div>
  </section>
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default Form