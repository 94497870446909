import React from 'react'
import { NavLink } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";


function Career() {

  return (
    <div>
    <ScrollToTop />
<section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
    
      <div className="col-md-6 intros text-start" style={{color:  "rgba(52, 9, 85, 0.933)", }}>
        <h1 className="display-2" style={{marginBottom: '05rem'}}>
          <span className="display-2--intro">Career</span>
          <span className="display-2--description lh-base">
            We’re always searching for amazing people to join our team. Take a look at our current openings.
          </span>
        </h1>
      
      </div>
     
      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/career/Career.jpg" alt="video illutration" className="img-fluid" />
        
         
        </div>
      </div>
    </div>
  </div>
  </section>


<section id="services" className="services my-4" >
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 28}}> Current Opportunities </h1>
      <div className="heading-line mb-1"></div>
    </div>
 
  <div className="container my-4">
    <div className=" row">
      <div className="col-sm-6">
        <div className="card mb-3" style={{maxWidth: 540}}>
          <div className="row g-0">
            <div className="col-md-4">
              <img src="folder/images/career/MERN-Stack-Development.png" className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">MERN Stack Developer</h5>
                <p className="card-text">We are hiring for MERN Stack Developer with 3 to 5  years of experience.</p>
                <p className="card-text"><small className="text-muted">5+ POSITIONS</small></p>
                   <NavLink to={"/Career/Form"}  className="btn btn-primary">Apply Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div className="col-sm-6">
  <div className="card mb-3" style={{maxWidth: 540}}>
    <div className="row g-0">
      <div className="col-md-4">
   
        <img src="folder/images/campanies/dot_net_original_logo_icon_146546.png" className="img-fluid rounded-start" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Dot Net Developer</h5>
          <p className="card-text">We are hiring for Dot Net Developer with 3 to 5 years of experience.</p>
          <p className="card-text"><small className="text-muted">3+ POSITIONS</small></p>
             <NavLink to={"/Career/Form"}  className="btn btn-primary">Apply Now</NavLink>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-sm-6">
  <div className="card mb-3" style={{maxWidth: 540}}>
    <div className="row g-0">
      <div className="col-md-4">
        <img src="folder/images/career/BDE.png" className="img-fluid rounded-start" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Business Development Executive</h5>
          <p className="card-text">We are hiring for Business Development Executive with 0 to 3 years of experience.</p>
          <p className="card-text"><small className="text-muted">2+ POSITIONS</small></p>
             <NavLink to={"/Career/Form"}  className="btn btn-primary">Apply Now</NavLink>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="col-sm-6">
  <div className="card mb-3" style={{maxWidth: 540}}>
    <div className="row g-0">
      <div className="col-md-4">
        <img src="folder/images/career/lead-generation.png" className="img-fluid rounded-start" alt="..." />
      </div>
      <div className="col-md-8">
        <div className="card-body">
          <h5 className="card-title">Lead Generation Executive</h5>
          <p className="card-text">We are hiring for Lead Generation Executive with 1 to 4 years of experience.</p>
          <p className="card-text"><small className="text-muted">1+ POSITIONS</small></p>
             <NavLink to={"/Career/Form"}  className="btn btn-primary">Apply Now</NavLink>
        </div>
      </div>
    </div>
  </div>
</div>


</div>
</div>
</div> 
</section>
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default Career