import React from 'react'
import { NavLink } from 'react-router-dom';

function FormHire() {
  window.scrollTo(0, 0);
  return (
    <div>
    
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
      <h3 className="display-3--title text-center" >Hire Top Filtered Talent With Us</h3>

      <form action="https://formsubmit.co/71595c79bb1f300f136eab11adc0ffbe" method="POST" className="row g-3" autoComplete="off">
      <input type="hidden" name="_next" value="https://anskeytechnology.com/"></input>
    <input type="hidden" name="_subject" value="Hiring Form"></input>
    <input type="hidden" name="_captcha" value="false"></input>
      <div className="col-md-6">
        <label for="inputEmail4" className="form-label">Name</label>
        <input type="text" name='Name' className="form-control" id="inputEmail4" />
      </div>
      <div className="col-md-6">
        <label for="inputPassword4" className="form-label">Email</label>
        <input type="email" name='Email' className="form-control" id="inputPassword4" />
        
        
      </div>
      <div className="col-md-4">
        <label for="inputState" className="form-label">Your Hiring Needs
        </label>

        <div className="form-check " >
          <input className="form-check-input" type="radio" name="Hiring Needs" id="flexRadioDefault1" />
          <label className="form-check-label" for="flexRadioDefault1">
            Part Time
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="Hiring Needs" id="flexRadioDefault2" checked />
          <label className="form-check-label" for="flexRadioDefault2">
            
Full Time
          </label>
        </div>
      </div>
      <div className="col-md-4">
        <label for="inputAddress2" className="form-label">Select one technology</label>
        <select name='technology' className="form-select" aria-label="Default select example">
          <option selected>Select...</option>
          <option value="1">MERN Stack</option>
          <option value="2">
            Dot Net</option>
          <option value="3">Angular</option>
          <option value="3">Flutter</option>
          <option value="3">Expres Js</option>
          <option value="3">Laravel</option>
          <option value="3">Mongo DB</option>
          <option value="3">My SQL</option>
          <option value="3">Next Js</option>
          <option value="3">Node Js</option>
          <option value="3">React-Native</option>
          <option value="3">Vue Js</option>
          <option value="3">Postgre SQL</option>

        </select>
      </div>
      <div className="col-md-4">

      
      <label for="inputPassword4" className="form-label">Phone Number</label>
      <input type="text" name='Phone Number' className="form-control" id="inputPassword4" />
    </div>
      <div className="col-md-12">
        <label for="exampleFormControlTextarea1" className="form-label">Massage</label>
        <textarea className="form-control" name='Massage' id="exampleFormControlTextarea1" rows="5" placeholder="Your Message.."></textarea>
       
      </div>
     
      <div className="col-12" style={{display: "flex", justifyContent: "center"}}>
        <button type="submit" className="btn btn-primary" >Submit</button>
      </div>

    </form>
   
  </div>
  
  <div className="col-md-6">
    <div className="bg-white p-4 text-start">
      <div className="">
        <div className=" " >
          <img src="folder/images/Hiring.png" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
        </div>
    </div>
  </div>
</div>
    </div>
  </div>
  </section>
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default FormHire