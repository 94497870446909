import React from "react";
import { NavLink } from "react-router-dom";

function Footer() {
    return(
        <>
        <footer className="footer">
        <div className="container">
          <div className="row">
            
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-call" viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
              </div>
              <div className="contact-box__info">
                <NavLink to={"tel:+91-63-57-5050-11"} className="contact-box__info--title">+91-63-57-5050-11</NavLink>
                <p className="contact-box__info--subtitle"> Contact Number</p>
              </div>
            </div>  
         
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-opened" viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <polyline points="3 9 12 15 21 9 12 3 3 9" />
                  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                  <line x1="3" y1="19" x2="9" y2="13" />
                  <line x1="15" y1="13" x2="21" y2="19" />
                </svg>
              </div>
              <div className="contact-box__info">
                <NavLink to={"mailto: info@anskeytechnology.com"} className="contact-box__info--title">info@anskeytechnology.com</NavLink>
                <p className="contact-box__info--subtitle">Online support</p>
              </div>
            </div>
            
            <div className="col-md-4 col-lg-4 contact-box pt-1 d-md-block d-lg-flex d-flex">
              <div className="contact-box__icon">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2" viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg>
              </div>
              <div className="contact-box__info">
                <NavLink to={"https://goo.gl/maps/JwPmngU5hXwygqJd6"} target="_blank"  className="contact-box__info--title">Thaltej , Ahmedabad-380054</NavLink>
                <p className="contact-box__info--subtitle">811 , Gala Empire , Opp. Durdarshan Tower </p>
              </div>
            </div>
          </div>
        </div>
      
        
        <div className="footer-sm" style={{backgroundColor: "#212121"}}>
          <div className="container">
            <div className="row py-4 text-center text-white">
              <div className="col-lg-5 col-md-6 mb-4 mb-md-0">
                Connect with us on social media
              </div>
              <div className="col-lg-7 col-md-6">
                <NavLink to={"https://www.facebook.com/Anskeytechnology"}  target="_blank"><i className="fab fa-facebook"></i></NavLink>
                <NavLink ><i className="fab fa-twitter"></i></NavLink>
                <NavLink to={"https://github.com/AnskeyTechnology"}  target="_blank"><i className="fab fa-github"></i></NavLink>
                <NavLink to={"https://www.linkedin.com/in/anskey-technology-aa979a237"}  target="_blank"><i className="fab fa-linkedin"></i></NavLink>
                <NavLink ><i className="fab fa-instagram"></i></NavLink>
              </div>
            </div>
          </div>
        </div>
      
      
        <div className="container mt-5">
          <div className="row text-white justify-content-center mt-3 pb-3">
            <div className="col-12 col-sm-6 col-lg-4 mx-auto">
              <h5 className="text-capitalize fw-bold">Anskey Technology</h5>
              <hr className="bg-white d-inline-block mb-4" style={{width: 60, height: 2}} />
              <p className="lh-lg">
               Join our community by using our services and grow your business. </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 mx-auto">
              <h5 className="text-capitalize fw-bold">Get in Touch</h5>
              <hr className="bg-white d-inline-block mb-4" style={{width: 60, height: 2}} />
              <ul className="list-inline campany-list">
                <li><NavLink to={"https://goo.gl/maps/JwPmngU5hXwygqJd6"} target="_blank">811 , Gala Empire , Opp. Durdarshan Tower  Thaltej , Ahmedabad-380054</NavLink></li>
                <li> <NavLink to={"mailto: info@anskeytechnology.com"} >info@anskeytechnology.com</NavLink></li>
                <li> <NavLink to={"tel:+91-63-57-5050-11"}>+91-63-57-5050-11</NavLink></li>
              
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 mb-4 mx-auto">
              <h5 className="text-capitalize fw-bold">Quick links</h5>
              <hr className="bg-white d-inline-block mb-4" style={{width: 60, height: 2}} />
              <ul className="list-inline campany-list">
                <li><NavLink to={"/Overview"} > Overview</NavLink></li>
                <li><NavLink to={"/Career"} >Career</NavLink></li>
                <li><NavLink to={"/Contact"} >Contact </NavLink></li>
                <li><NavLink to={"/FormHire"} >Hire Now</NavLink></li>
          
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 mx-auto">
              <h5 className="text-capitalize fw-bold">Company Services</h5>
              <hr className="bg-white d-inline-block mb-4" style={{width: 60, height: 2}} />
              <ul className="list-inline campany-list">
                <li><NavLink to={"/WebDevelopment"} >Web Development</NavLink></li>
                <li><NavLink to={"/UiUxDesign"} >UI/UX Design</NavLink></li>
                <li><NavLink to={"/MobileDevelopment"} >Mobile Development</NavLink></li>
                <li><NavLink to={"/ECommerceWebDevelopment"} >ECommerce Web Development</NavLink></li>
                <li><NavLink to={"/CMSDevelopment"} >CMS Development</NavLink></li>
                <li><NavLink to={"/RemoteHiring"} >Remote Hiring</NavLink></li>
              </ul>
            </div>
          </div>
        </div>
      
      
        <div className="footer-bottom pt-5 pb-5">
          <div className="container">
            <div className="row text-center text-white">
              <div className="col-12">
                <div className="footer-bottom__copyright">
                  &copy; 
      Copyright  2023 All Rights Reserved by <NavLink to={"/"} >Anskey Technology</NavLink> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
        </>
    );
    
};

export default Footer