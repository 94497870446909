import Home from "./Components/Home";
import Career from "./Components/Career";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CmsDevlopment from "./Components/CmsDevlopment";
import Contact from "./Components/Contact";
import Ecommerce from "./Components/Ecommerce";
import FormHire from "./Components/FormHire";
import Form from "./Components/Form";
import RemoteHiring from "./Components/RemoteHiring";
import MobileDevelopment from "./Components/MobileDevelopment";
import Overview from "./Components/Overview";

import Design from "./Components/Design";
import VisionMission from "./Components/VisionMission";
import WebDevlopment from "./Components/WebDevlopment";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";






function App() {
  return (
    <div>
      <div className="App">
        <Router>
          <Navbar />
          
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/CMSDevelopment" element={<CmsDevlopment />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/ECommerceWebDevelopment" element={<Ecommerce />} />
            <Route path="/FormHire" element={<FormHire />} />
            <Route path="/Career/Form" element={<Form />} />
            <Route path="/RemoteHiring" element={<RemoteHiring />} />
            <Route path="/MobileDevelopment" element={<MobileDevelopment />} />
            <Route path="/Overview" element={<Overview />} />
            <Route path="/UiUxDesign" element={<Design />} />
            <Route path="/Vision&Mission" element={<VisionMission />} />
            <Route path="/WebDevelopment" element={<WebDevlopment />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
