import React from 'react'
import { NavLink } from 'react-router-dom';

function MobileDevelopment() {
  window.scrollTo(0, 0);
  return (
    <div>
 
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
      
      <div className="col-md-6 intros text-start"  style={{color:  "rgba(52, 9, 85, 0.933)", }}>
        <h1 className="display-2">
          <span className="display-2--intro">Mobile Development </span>
          <span className="display-2--description lh-base">
            "Unlocking the power of mobility through innovative and seamless app experiences."
          </span>
        </h1>
   
      </div>
      
      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/img/the_complete_guide_to_mobile_app_development.png" alt="video illutration" className="img-fluid" />
          <NavLink className="glightbox position-absolute top-50 start-50 translate-middle">
           
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  </section>


<section id="services" className="services">
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 25}}>About Mobile Development </h1>
      <div className="heading-line mb-1"></div>
    </div>
  
    <div className="row pt-2 pb-2 mt-0 mb-3">
      <div className="col-md-6 ">
        <div className="services__content" >
          <p>
          </p>
          <h3 className="display-3--title ">Mobile App Development </h3>
          <p className="lh-lg">
            we deliver best quality of mobile app developement to unlock your business growth
          </p>
          <p className="lh-lg">
            Mobile development is a captivating journey, where innovation and user-centricity converge. It's the craft of transforming ideas into tangible experiences, leveraging the power of 
            cutting-edge technologies to create seamless, intuitive, and immersive mobile applications.
          </p>
          
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <img src="folder/images/img/mobile-dev-process 02.png" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
            </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>


<section id="services" className="services">
  <div className="container my-4">
<div className="row text-center">
  <h3 className="fw-bold lead mb-3">FAQ About Mobile Development  </h3>
  <div className="heading-line "></div>
</div>
  
    <div className="row">
     
 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="services__content ">
          <div className="icon d-block fas fa-code-compare"></div>
          <h3 className="display-3--title mt-1">Mobile Development </h3>
          <div className="accordion my-4" id="accordionExample">
           
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is mobile development?

                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Mobile development refers to the process of creating mobile applications for smartphones and tablets. It involves designing, coding, testing, and deploying 
                  applications that run on mobile operating systems like iOS (iPhone/iPad) or Android. </div>
              </div>
            </div>
              
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  What are the primary mobile platforms?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The two major mobile platforms are iOS and Android. iOS is the operating system used on Apple devices, such as iPhones and iPads. Android, developed by Google, is
                   an open-source platform used by a wide range of smartphone manufacturers.</div>
              </div>
            </div>
             
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What programming languages are commonly used for mobile development?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  For iOS app development, the primary programming language is Swift, although Objective-C is still used in some legacy applications. For Android app development, Java has traditionally been the primary language,
                   but Kotlin has gained popularity in recent years as a modern alternative.</div>
              </div>
            </div>
           
          </div>
         
        </div>
      </div>
  </div>
    </div>
  </section> 
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    
    </div>
  )
}

export default MobileDevelopment