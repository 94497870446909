import React from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

function Contact() {
  return (
    <div>
    <ScrollToTop />
<section id="home" className="intro-section">
<div className="container">
  <div className="row text-center">
    <h1 className="display-3 fw-bold text-capitalize">Contact Us</h1>
    <div className="heading-line"></div>
 
  </div>

 
  <div className="row text-white">
    <div className="col-12 col-lg-6 shadow  p-3">
      <div className="">
        <img src="folder/images/Contact.png" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
      </div>
    </div>
    <div className="col-12 col-lg-6 bg-white shadow p-3">
    <div className="form w-100 pb-2">
      <h4 className="display-3--title mb-5">Let's start your project</h4>
      <form action="https://formsubmit.co/71595c79bb1f300f136eab11adc0ffbe" method="POST" className="row">
      <input type="hidden" name="_next" value="https://anskeytechnology.com/"></input>
      <input type="hidden" name="_subject" value="Contact Form"></input>
      <input type="hidden" name="_captcha" value="false"></input>
        <div className="col-lg-6 col-md mb-3">
          <label for="inputAddress2" className="" style={{color: "#212121"}} >Full Name</label>
          <input type="text"  id="inputFirstName" name='Name' className="shadow form-control form-control-lg" />
        </div>
        <div className="col-lg-6 col-md mb-3">
          <label for="inputAddress2" className=""  style={{color: "#212121"}} >Email Address</label>
          <input type="email"  id="inputLastName" name='Email' className="shadow form-control form-control-lg" />
        </div>
        <div className="col-lg-6 mb-3  ">
          <label for="inputAddress2" className="form-label " style={{color: "#212121"}} >What do you desire?</label>
          <select className="form-select form-control-lg shadow " name='Services' aria-label="Default select example" style={{lineHeight: 2.1}}>
            <option selected >Select...</option>
            <option value="1">Web Development</option>
            <option value="2">
              UI/UX Design</option>
            <option value="3">Mobile Development</option>
            <option value="4">eCommerce Web Development</option>
            <option value="5">CMS Development</option>
            <option value="6">Other</option>
           
  
          </select>
        </div>
        <div className="col-lg-6 mb-3">
          <label for="inputAddress2" className="form-label"  style={{color: "#212121"}} >Phone Number</label>
          <input type="text"  id="inputEmail" name='Number'  className="shadow form-control form-control-lg" />
        </div>
        <div className="col-lg-12 mb-3">
          <label for="inputAddress2" className="form-label"  style={{color: "#212121"}} >Write your message</label>
          <textarea name="message"  id="message" rows="8" className="shadow form-control form-control-lg"></textarea>
        </div>
        <div className="text-center d-grid mt-1">
          <button type="submit" className="btn btn-primary rounded-pill pt-3 pb-3">
            submit
            <i className="fas fa-paper-plane"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  
    <iframe className="my-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.3538713713174!2d72.52253367504264!3d23.047484579156304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84adc127937d%3A0x67c199e5da5efd06!2sAnskey%20Technology%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1689600013286!5m2!1sen!2sin" width="600"
     height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  
  </div>
</div>
</section>
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default Contact