import React from 'react'
import { NavLink } from 'react-router-dom';

function Overview() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container ">
    <div className="row text-center">
      <h1 className="display-3 fw-bold">Overview</h1>
      <div className="heading-line"></div>
    </div>

    <div className="row pt-2 pb-2 mt-0 mb-3">
    <div className="col-md-6 border-right">
    <div className="services__ntent">
      <p style={{color:"rgba(66, 27, 96, 0.933)", fontSize:"26px" }}></p>
      <h3 className="display-3--title mt-1">
        Leading Web & Mobile App Development Company
      </h3>
      <p className="lh-lg my-5">
        We are one of the fastest growing software development company
        which offer high-end software solutions. Right from website
        development to mobile app development, web applications to
        portal development, we have the expert strength to cover the
        different facets of the business world throughout
        technology-driven solutions. We focus on delivering excellent
        quality at affordable rates.
      </p>
      <NavLink to={"/Overview"} className="nav-link">
        {" "}
     
      </NavLink>
    </div>
  </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <p>Analysis</p>
              <div className="progress">
                
                <div className="progress-bar bg-success" role="progressbar" style={{width: '80%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Designing</p>
              <div className="progress">
                <div className="progress-bar bg-info" role="progressbar" style={{width: '90%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Development</p>
              <div className="progress">
                <div className="progress-bar bg-warning" role="progressbar" style={{width: '95%'}}aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p>Testing</p>
              <div className="progress">
                <div className="progress-bar bg-danger" role="progressbar" style={{width: '90%'}}aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-4 col-lg-4">
        <div className="campanies__logo-box shadow-sm">
       
          <h3 className="display-3--title mt-1">25+ </h3>
      <p className="lh-lg">
        Expertise
      </p>
        </div>
      </div>
      <div className="col-md-4 col-lg-4">
        <div className="campanies__logo-box shadow-sm">
          <h3 className="display-3--title mt-1">39+ </h3>
          <p className="lh-lg">
            Happy Customers
          </p>
        </div>
      </div>
      <div className="col-md-4 col-lg-4">
        <div className="campanies__logo-box shadow-sm">
          <h3 className="display-3--title mt-1">43+ </h3>
          <p className="lh-lg">
            Completed Projects
          </p>
        </div>
      </div>     
      </div>
    </div>
  </div>
  </section>
  <section id="campanies" className="campanies">
  <div className="container">
    <div className="row text-center">
      <h3 className="fw-bold lead mb-3">Technologies We Specialize In</h3>
      <div className="heading-line mb-5"></div>
    </div>
  </div>
  
  <div className="container">
    <div className="row">
    <div className="col-md-4 col-lg-2">
    <div className="campanies__logo-box shadow-sm">
     <NavLink> <img src="folder/images/campanies/mongodb-svgrepo-com.jpg" alt="Campany 5 logo" title="Campany 5 Logo" className="img-fluid" /></NavLink>
      <p> Mongo DB</p>
    </div>
  </div>
  <div className="col-md-4 col-lg-2">
  <div className="campanies__logo-box shadow-sm">
   <NavLink> <img src="folder/images/campanies/expressjs.png" alt="Campany 3 logo" title="Campany 3 Logo" className="img-fluid" /></NavLink>
    <p> Expres Js</p>
  </div>
</div>
<div className="col-md-4 col-lg-2">
<div className="campanies__logo-box shadow-sm">
<NavLink> <img src="folder/images/campanies/react-native.png" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" /></NavLink> 
  <p> React Js</p>
</div>
</div>
<div className="col-md-4 col-lg-2">
<div className="campanies__logo-box shadow-sm">
<NavLink><img src="folder/images/campanies/node js.png" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" /></NavLink> 
<p>Node Js</p>
</div>
</div>
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
        <NavLink>  <img src="folder/images/campanies/Angular.png" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" /></NavLink>
          <p> Angular</p>
        </div>
      </div>
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
        <NavLink>  <img src="folder/images/campanies/flutter.png" alt="Campany 2 logo" title="Campany 2 Logo" className="img-fluid" /></NavLink>
          <p> Flutter</p>
        </div>
      </div>
      
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
        <NavLink> <img src="folder/images/campanies/react-native.png" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" /></NavLink> 
          <p> React-Native</p>
        </div>
      </div>
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
         <NavLink> <img src="folder/images/campanies/laravel.png" alt="Campany 4 logo" title="Campany 4 Logo" className="img-fluid" /></NavLink>
          <p> Laravel</p>
        </div>
      </div>
     
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
         <NavLink> <img src="folder/images/campanies/mysql-logo-svgrepo-com.jpg" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" /></NavLink>
          <p> My SQL</p>
        </div>
      </div>
     
   
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
         <NavLink> <img src="folder/images/campanies/vuejs.jpg" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" /></NavLink>
          <p> Vue Js</p>
        </div>
      </div>
   
      <div className="col-md-4 col-lg-2">
        <div className="campanies__logo-box shadow-sm">
         <NavLink> <img src="folder/images/campanies/dot_net_original_logo_icon_146546.png" alt="Campany 6 logo" title="Campany 6 Logo" className="img-fluid" style={{width: "96px", height: "96px"}} /></NavLink>
          <p> Dot Net</p>
        </div>
      </div>
      <div className="col-md-4 col-lg-2">
      <div className="campanies__logo-box shadow-sm">
      <NavLink> <img src="folder/images/devop.png" alt="Campany 6 logo" title="Campany 6 Logo" class="img-fluid" style={{width: "96px", height: "96px"}} /></NavLink>
        <p> Dev-Ops</p>
      </div>
    </div>
    </div>
  </div>
</section>
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
  )
}

export default Overview