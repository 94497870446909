import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark menu shadow fixed-top">
        <div className="container">
          <NavLink className="navbar-brand" to={"/"}>
            <img
              src="folder/images/Logo.png"
              alt="logo image"
              style={{ width: "194px", height: "70px" }}
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
            style={{ lineHeight: 2 }}
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to={"/"}
                  style={{ color: "rgba(66, 27, 96, 0.933)" }}
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item dropdown ">
                <NavLink
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "rgba(66, 27, 96, 0.933)" }}
                >
                  About Us
                </NavLink>
                <ul className="dropdown-menu " aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      to={"/Overview"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      Overview
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/Vision&Mission"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      {" "}
                      Vision & Mission{" "}
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <NavLink
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "rgba(66, 27, 96, 0.933)" }}
                >
                  Services
                </NavLink>
                <ul className="dropdown-menu " aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      to={"/WebDevelopment"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      Web Development
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/UiUxDesign"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      UI/UX Design
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/MobileDevelopment"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      Mobile Development
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/ECommerceWebDevelopment"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      ECommerce Web Development
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/CMSDevelopment"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      CMS Development
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      to={"/RemoteHiring"}
                      className="dropdown-item"
                      style={{ color: "rgba(66, 27, 96, 0.933)" }}
                    >
                      Remote Hiring
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/Career"}
                  style={{ color: "rgba(66, 27, 96, 0.933)" }}
                >
                  Career
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={"/Contact"}
                  className="nav-link"
                  style={{ color: "rgba(66, 27, 96, 0.933)" }}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
            <NavLink to={"/FormHire"} className="nav-link">
              {" "}
              <button
                type="button"
                className="rounded-pill btn-rounded text-center"
              >
                Hire Now
                <span>
                  <i className="fas fa-laptop"></i>
                </span>
              </button>
            </NavLink>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
