import React from 'react'
import { NavLink } from 'react-router-dom';

function VisionMission() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" class="intro-section">
    <div class="container">
      <div class="row text-center">
        <h3 class="fw-bold lead mb-3">Vision & Mission </h3>
        <div class="heading-line mb-5"></div>
      </div>
    </div>
  
    <div class="container">
      <div class="row">
        
        <div class="col-md-6 col-lg-6">
          <div class="campanies__logo-box shadow-sm">
          <NavLink>  <img src="folder/images/vision.jpg" alt="Campany 1 logo" title="Campany 1 Logo" class="img-fluid" /></NavLink>
            <h1 class="display-3--title my-4"> Vision</h1>
            <p class="lh-lg">At Anskey Technology Pvt Ltd, our unwavering vision is to lead the way in shaping a brighter tomorrow for all. We are driven by a deep commitment to harness the power of innovation, embracing cutting-edge technologies, and fostering a 
              culture of purpose that transcends borders and positively impacts communities worldwide.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="campanies__logo-box shadow-sm">
          <NavLink>  <img src="folder/images/mission.jpg" alt="Campany 1 logo" title="Campany 1 Logo" class="img-fluid" /></NavLink>
            <h1 class="display-3--title my-4"> Mission</h1>
            <p class="lh-lg"> At Anskey Technology Pvt Ltd, our mission goes far beyond the realm of business. 
               We are on a resolute journey to empower lives, 
               ignite progress, and create a lasting impact that transcends generations. 
               Rooted in purpose and driven by a profound sense of responsibility,
               our mission serves as a guiding light.</p>
          </div>
        </div>
        
       
      </div>
    </div>
   
   
  </section>
  <div class="container my-4">
    <div class="row text-center">
      <h3 class="fw-bold lead ">4 Steps To Success</h3>
      <div class="heading-line "></div>
    </div>
  </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="campanies__logo-box shadow-sm">
          
            <h1 class="display-3--title my-4"> Vision</h1>
            <p class="lh-lg">Amidst the vibrant cityscape, a radiant sunset paints the sky in hues of gold and orange, casting a warm glow over the urban sprawl</p>
          </div>
          <div class="campanies__logo-box shadow-sm">
          
            <h1 class="display-3--title my-4"> Solution</h1>
            <p class="lh-lg">
              Unlocking the puzzle to success. Our solution is a comprehensive and innovative approach that addresses complex challenges with simplicity and efficiency. </p>
          </div>
           
        </div>
        <div class="col-md-6 col-lg-4 " style={{marginTop: 60}}>
          
         <img src="folder/images/Value.png" alt="Campany 1 logo" title="Campany 1 Logo" class="img-fluid" />
            
         
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="campanies__logo-box shadow-sm">
         
            <h1 class="display-3--title my-4"> Mission</h1>
            <p class="lh-lg">Empowering minds, transforming lives. Our mission is to ignite a passion for knowledge, cultivate creativity, and inspire positive change. </p>
          </div>
          <div class="campanies__logo-box shadow-sm">
         
            <h1 class="display-3--title my-4"> Process</h1>
            <p class="lh-lg">
             Navigating the journey of transformation. Our process is a well-crafted roadmap that guides you from vision to reality.</p>
          </div>
        </div>
        
       
      </div>
    </div>  
  <NavLink  className="shadow btn-primary rounded-circle back-to-top">
  <i className="fas fa-chevron-up"></i>
  </NavLink>
    
    </div>
  )
}

export default VisionMission