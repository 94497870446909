import React from 'react'
import { NavLink } from 'react-router-dom';

function RemoteHiring() {
  window.scrollTo(0, 0);
  return (
    <div>
    <section id="home" className="intro-section">
  <div className="container">
    <div className="row align-items-center text-white">
    
      <div className="col-md-6 intros text-start" style={{color:  "rgba(52, 9, 85, 0.933)", }}>
        <h1 className="display-2">
          <span className="display-2--intro">Remote Hiring </span>
          <span className="display-2--description lh-base">
            "Unlocking global talent through seamless remote hiring solutions."
          </span>
        </h1>
      
        
      </div>

      <div className="col-md-6 intros text-end" >
        <div className="video-box">
          <img src="folder/images/img/remote-hiring.png" alt="video illutration" className="img-fluid" />
          <NavLink className="glightbox position-absolute top-50 start-50 translate-middle">
            
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  </section>


<section id="services" className="services">
  <div className="container my-4">
    <div className="row text-center">
      <h1 className="display-3 fw-bold" style={{fontSize: 25}}>About Remote Hiring </h1>
      <div className="heading-line mb-1"></div>
    </div>
 
    <div className="row pt-2 pb-2 mt-0 mb-3">
      <div className="col-md-6 ">
        <div className="services__content">
          <p></p>
          <h3 className="display-3--title ">Remote Hiring </h3>
          <p className="lh-lg">
            Remote hiring is more than just a trend; it's a paradigm shift that redefines the way we work. It opens up a world of possibilities, enabling businesses to
             assemble teams of diverse skillsets, experiences, and perspectives, regardless of physical location.
          </p>
          
        </div>
      </div>
      <div className="col-md-6">
        <div className="bg-white p-4 text-start">
          <div className="">
            <div className=" " >
              <img src="folder/images/img/hiring-remote-2.jpg" alt="Campany 1 logo" title="Campany 1 Logo" className="img-fluid" />
            </div>
        </div>
      </div>
    </div>
  </div>

  </div>
  
</section>




<section id="services" className="services">
  <div className="container my-4">
<div className="row text-center">
  <h3 className="fw-bold lead mb-3">FAQ About Remote Hiring  </h3>
  <div className="heading-line "></div>
</div>
  
    <div className="row">
     
 
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
        <div className="services__content ">
          <div className="icon d-block fas fa-code-compare"></div>
          <h3 className="display-3--title mt-1">Remote Hiring </h3>
          <div className="accordion my-4" id="accordionExample">
          
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is remote hiring?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Remote hiring refers to the process of recruiting and onboarding employees or freelancers who will work remotely, often from different locations. It involves sourcing candidates, conducting interviews, assessing skills, 
                  and making hiring decisions, all done remotely using online communication tools and platforms. </div>
              </div>
            </div>
              
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How do I find remote candidates?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  There are several methods to find remote candidates:

                  Online job boards: Post remote job openings on platforms that specialize in remote work, such as Remote.co, We Work Remotely, or FlexJobs.
                  Freelance platforms: Utilize platforms like Upwork, Freelancer, or Fiverr to find freelancers with the specific skills you need.
                  Social media and professional networks: Leverage LinkedIn, Twitter, or relevant industry-specific groups to connect with professionals seeking remote opportunities.
                  Referrals: Ask your existing network, employees, or freelancers for referrals or recommendations.</div>
              </div>
            </div>
             
            <div className="accordion-item shadow mb-3">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  What tools can I use for remote hiring?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  There are various tools available to facilitate remote hiring processes, including:

                  Video conferencing tools: Use platforms like Zoom, Microsoft Teams, or Google Meet for remote interviews and virtual meetings with candidates.
                  Applicant Tracking Systems (ATS): Utilize online systems like Greenhouse, Lever, or Workable to streamline the recruitment and hiring process.
                  Collaboration and communication tools: Platforms such as Slack, Microsoft Teams, or Asana help facilitate communication and collaboration with remote team members throughout the hiring process.
                  Skill assessment platforms: Tools like HackerRank, Codility, or ProProfs can be used to evaluate technical skills through coding challenges or quizzes.
                  Document sharing and e-signature tools: Services like Google Drive, Dropbox, or DocuSign allow secure sharing of documents and electronic signatures for offer letters and contracts.</div>
              </div>
            </div>
           
          </div>
         
        </div>
      </div>

    
  </div>
    </div>
  </section> 
<NavLink  className="shadow btn-primary rounded-circle back-to-top">
<i className="fas fa-chevron-up"></i>
</NavLink>
    </div>
    
  )
}

export default RemoteHiring